import { style as s } from "typestyle";
import * as Style from "../../styles";

export const dropdown = s({
    position: "absolute",
    // top: "38px",
    // left: "-1px",
    border: "1px solid rgb(197, 197, 197)",
    background: Style.colors.black2,
    padding: "0",
    color:  Style.colors.white,
    textAlign: "left",
    borderRadius: "0.25rem;"
})

export const active = s({
    color: Style.colors.black2,
    backgroundColor: Style.colors.white,
  })

export const dropdownList = s({
    position: "relative",
    margin: "0",
    padding: "0.375rem 0.88rem",
    borderBottom: "1px solid rgb(235, 235, 235)",
    $nest: {
        "&:hover": {
            backgroundColor: Style.colors.white,
            color: Style.colors.black2,
            cursor: "pointer"
        },
    }
})