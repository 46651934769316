import React, { useEffect, useState } from 'react';
import * as style from "./Dropdown.style";

type DropDownProps = {
  items: string[];
  showDropDown: boolean;
  toggleDropDown: Function;
  itemSelection: Function;
};

const Dropdown: React.FC<DropDownProps> = ({
  items,
  itemSelection,
}: DropDownProps): JSX.Element => {
  const [showDropDown, setShowDropDown] = useState<boolean>(false);

  const onClickHandler = (item: string): void => {
    itemSelection(item);
  };

  useEffect(() => {
    setShowDropDown(showDropDown);
  }, [showDropDown]);

  return (
    <>
      <div className={showDropDown ? (style.dropdown && style.active) : (style.active && style.dropdown)}>
        {items.map(
          (item: string, index: number): JSX.Element => {
            return (
              <p
                className={style.dropdownList}
                key={index}
                onClick={(): void => {
                  onClickHandler(item);
                }}
              >
                {item}
              </p>
            );
          }
        )}
      </div>
    </>
  );
};

export {Dropdown};