import { NestedCSSProperties } from "typestyle/lib/types";
import * as colors from "./colors";
const headingBase: NestedCSSProperties = {
  fontWeight: 600,
  // fontStyle: 'italic',
  textTransform: "uppercase",
};

export const smallHeader = {
  ...headingBase,
  fontSize: 16,
};

export const mediumHeader = {
  ...headingBase,
  fontSize: 22,
};

export const largeHeader = {
  ...headingBase,
  fontSize: 28,
};

export const extraLargeHeader = {
  ...headingBase,
  fontSize: 40,
};

export const fontSize = {
  extraSmall: 10,
  small: 14,
  smallMed: 18,
  medium: 20,
  medLarge: 24,
  large: 28,
  xl: 36,
  extraLarge: 36,
  xxl: 44,
  xxxl: 58,
  xxxxl: 64
};

export const color = {
  main: colors.black2,
  subtle: colors.darkTranslucent300,
  inverse: colors.white,
};

export const font = {
  main: "Oswald",
  second: "Poppins",
  third: "Roboto",
  fourth: "Brawler",
  fifth: "Rubik",
  Montserrat: "Montserrat"
};
