import { style as s, media } from "typestyle";
import * as Style from "../../styles";


export const container = s({
    background: "linear-gradient(315deg, #d9d9d9 0%, #f6f2f2 4%)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "auto",
    height: "100vh"
})

export const faceImage = s({
    marginTop: "35px",
    maxHeight: "90vh",
},
media(
    { maxWidth: Style.screensize.xsMax },
    {
        width: "370px",
    }
))

export const typingContainer = s({
    height: '150px',
    width: '250px',
    border: `2px solid rgba(0 0 0 90)`,
    borderRadius: '4px',
    padding: '6px',
    fontSize: Style.text.fontSize.medium,
    position: 'absolute',
    // top: '8rem',
    // right: '5rem',
    $nest: {
        "&:hover": {
          borderColor: Style.colors.palletOrange,
          transition: '1s',
        },
    }
}
,
media(
    { minWidth: Style.screensize.mdMax },
    {
        position: 'absolute',
        top: '8rem',
        right: '22rem',
    }
),
media(
    { minWidth: Style.screensize.smMax },
    {
        position: 'absolute',
        top: '8rem',
        right: '15rem',
    }
),
media(
    { maxWidth: Style.screensize.smMax },
    {
        height: '200px',
        width: '200px',
        position: 'absolute',
        top: '6rem',
        right: '8rem',
    }
),
media(
    { maxWidth: Style.screensize.xsMax },
    {
        backgroundColor: "rgba(225, 225, 225, 0.5)",
        width: '95%',
        position: 'absolute',
        top: "30rem",
        margin: "auto",
        bottom: 90,
        left: 0,
        right: 0
    }
)
)

export const speechBubbleArrow = s({
    width: '0',
    height: '0',
    border: '15px solid transparent',
    borderBottom: 0,
    borderLeft: `1px solid rgba(0,0,0,60)`,
    borderTop: `30px solid rgba(0,0,0,60)`,
    position: 'absolute',
    bottom: '-30px',
    left: '10px',
    zIndex: 5,
    $nest: {
        "&:hover": {
          borderLeftColor: "rgba(111,35,72,255)",
          borderTopColor: "rgba(111,35,72,255)",
          transition: '0.25s',
        },
    }
},
media(
    { maxWidth: Style.screensize.xsMax },
    {
        display: "none"
    }
))

export const button = s({
    backgroundColor: Style.colors.black2,
    borderRadius: 2,
    fontSize: Style.text.fontSize.large,
    padding: "15px 30px",
    $nest: {
        "&:hover": {
          backgroundColor: "rgba(111,35,72,255)",
          transition: '0.25s',
        },
    }
},
media(
    { maxWidth: Style.screensize.xsMax },
    {
       position: 'absolute',
       bottom: "1rem",
       
 
    }
))
