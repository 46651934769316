import * as React from "react";
import * as style from "./Shop.style";
import * as Components from "../../components";
import { connect } from "react-redux";
import { Product } from "shopify-buy";
import { RootState } from "../../store/reducers";

export interface StateProps {
  products: Product[] | null;
}

const ShopInternal: React.FC<StateProps> = ({ products }) => {
  return (
    <>
    <div className={style.backgroundImg}></div>
      <Components.Page>
        {products ? (
          <div className={style.productContent}>
            {products.map((product) => (
              <Components.Shopify.Product 
                key={product.id}
                product={product}
                className={style.product}
              />
            ))}
          </div>
        ) : <>Loading . . .</>}
      </Components.Page>
    </>
  );
};

ShopInternal.displayName = "Shop";
export const Shop = connect((state: RootState) => {
  return {
    products: state.shopify.products,
  };
}, {})(ShopInternal);