import { media, style as s } from "typestyle";
import * as Style from "../../styles";


export const menuButton = s({
  color: Style.colors.black3,
  $nest: {
    "&:hover": {
      background: "transparent",
      borderRadius: 8,
      color: Style.colors.primary,
      transition: "0.3s",
    },
  },
});

export const navItemOne = s({
  position: "fixed",
  left: 0,
  top: 0,
  paddingLeft: 10,
  paddingTop: 10,
  textTransform: "uppercase",
  fontSize: "30px",
  fontWeight: 900,
  padding: "0 1rem 0 1rem",
  color: Style.colors.shade700,
  $nest: {
    "&:hover": {
      color: Style.colors.primary,
      transition: "0.3s",
    },
  }
})

export const navItemTwo = s({
  position: "fixed",
  right: 0,
  top: 0,
  margin: "auto",
  marginRight: 10,
  padding: "0.25em 0.75em",
  height: "30px",  
  $nest: {
    "&:hover": {
      transition: "0.3s",
      opacity: 0
    }
  }
})


export const navItemThree = s({
  position: "fixed",
  right: 0,
  bottom: 0,
  margin: "auto",
  paddingLeft: 10,
  textTransform: "uppercase",
  fontSize: "30px",
  fontWeight: 900,
  padding: "0 1rem 0 1rem",
  color: Style.colors.shade700,
  $nest: {
    "&:hover": {
      color: Style.colors.primary,
      transition: "0.3s",
    }
  }
})

export const navItemFour = s({
  position: "fixed",
  left: 0,
  bottom: 0,
  margin: "auto",
  marginRight: 10,
  padding: "0.25em 0.75em",
  height: "30px",  
  $nest: {
    "&:hover": {
      transition: "0.3s",
      opacity: 0
    }
  }
})

export const navItemFive = s({
  position: "fixed",
  top: 0,
  right: 0,
  margin: "auto",
  marginRight: "3rem",
  padding: "0.25em 0.75em",
  height: "30px",
  $nest: {
    "&:hover": {
      transition: "0.3s",
      opacity: 0
    }
  }
})

export const desktopContentWLogo = s({
  display: "flex",
  justifyContent: "Space-between",
});

export const mobileContent = s(
  media(
    { minWidth: Style.screensize.mdMin },
    {
      display: "none",
    }
  )
);

export const left = s({
  display: "flex",
  alignItems: "center",
  flex: 1,
});

export const right = s({
  flex: 1,
  display: "flex",
  justifyContent: "flex-end",
  $nest: {
    "&>*": {
      marginRight: 20,
    },
  },
});

export const header = s(
  Style.text.largeHeader,
  media({ maxWidth: Style.screensize.xsMax }, { display: "none" })
);

export const link = s({
  textDecoration: "none",
  color: Style.colors.black1,
});

export const nav = s({
  backgroundColor: "transparent",
  boxShadow: "none",
  fontFamily: Style.text.font.fifth,
  flexGrow: 1,
});

export const modalStyles = {
  content: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    background: Style.colors.primary,
    opacity: 0.6,
  },
  overlay: {
    zIndex: 1000,
  },
};

export const cancelIcon = s({
  color: "rgba(111,35,72,255)",
  fontSize: Style.text.fontSize.xxl,
  $nest: {
    "&:hover": {
      color: Style.colors.primary,
      transition: "0.3s",
    },
  },
});

export const menuItem = s(
  {
    marginBottom: 5,
    width: "100%",
    textAlign: "center",
    fontSize: Style.text.fontSize.xxxxl,
    textTransform: "uppercase",
    fontWeight: 900,
    padding: 5,
    borderRadius: 10,
    $nest: {
      "& a": {
        color: "rgba(111,35,72,255)",
        opacity: 0.95,
        $nest: {
          "&:hover": {
            color: Style.colors.white,
            transition: "0.3s",
          },
        },
      },
    },
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      marginBottom: 40,
      fontSize: 120,
    }
  )
);

export const mobileButton = s({
  width: "100%",
});

export const more = s({
  display: "flex",
  alignItems: "center",
  flex: 1,
  height: "100%",
  marginRight: 30,
});

export const cart = s(
  {
    position: "relative",
    $nest: {
      "& svg": {
        fontSize: 30,
      },
    },
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      $nest: {
        "& svg": {
          fontSize: 40,
        },
      },
    }
  )
);

export const cartCount = s(
  {
    fontFamily: Style.text.font.third,
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: Style.colors.white,
    fontWeight: 600,
    backgroundColor: Style.colors.palletPurple,
    right: 4,
    top: 4,
    fontSize: 12,
    height: 18,
    width: 18,
    borderRadius: 16,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      right: 1,
      top: 1,
      fontSize: 16,
      height: 24,
      width: 24,
      borderRadius: 16,
    }
  )
);
