import { combineReducers } from "redux";
import { auth } from "./auth";
import { shopify } from './shopify';

export const rootReducer = combineReducers({
  auth,
  shopify,
});

export type RootState = ReturnType<typeof rootReducer>;
