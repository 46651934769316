import * as React from "react";
import * as style from "./About.style";
import GoogleFontLoader from "react-google-font-loader";
import BandPhoto from "../../assets/band-photo.jpeg"

const family = "Monoton";

const About: React.FC = () => {
  return (
    <>
      <div>
        <GoogleFontLoader
          fonts={[
            { font: family, weights: [100, 200, 300, 400, 500, 600, 800, 900] },
          ]}
        />
      </div>
      <div className={style.background}>
        <div className={style.component}>
          {/* <div className={style.title}>About Us</div> */}
          <div className={style.subTitleSection}>
            <div className={style.subTitle}>
              <div>Sandy - Psych - Rock</div>
            </div>
          </div>
          
          <div className={style.aboutWrapper}>
            <img src={BandPhoto} className={style.bandPhoto} alt="Band" />
            <div className={style.articleSubContainer}>
            <div className={style.aboutUs}>
              "Hotbed is a four-piece rock outfit based in Philadelphia, PA. Drawing on influences ranging from classical music to reggae, they’ve created an infectious brand of indie rock. They’re a group of friends first and foremost who happen to also  make really cool music together. New to the Philly music scene, Hotbed is already proving themselves to be one of the best up-and-coming bands in the city."
            </div>
              <h3 className={style.articleTitles}>
                Articles
              </h3>
              <p className={style.articleSubTitles}>Read our feature in Out &amp; About Magazine:  
                <a className={style.articleLinks} target="_blank" rel="noopener noreferrer" href="https://outandaboutnow.com/2019/11/30/theyre-hot/">
                  https://outandaboutnow.com/2019/11/30/theyre-hot/
                </a>
              </p>
              <p className={style.articleSubTitles}>Interview with the band:  
                <a className={style.articleLinks} target="_blank" rel="noopener noreferrer" href="https://thequeuemag.com/hotbed-interview/">
                  https://thequeuemag.com/hotbed-interview/
                </a> 
              </p>
              <h3 className={style.articleTitles}>
                Concert Reviews
              </h3>
              <p className={style.articleSubTitles}>JOHNY BRENDA'S, Philadelphia, PA -  
                <a className={style.articleLinks} target="_blank" rel="noopener noreferrer" href="https://phillylive.org/home/2019/10/26/rfadominyhotbed">
                  https://phillylive.org/home/2019/10/26/rfadominyhotbed
                </a> 
              </p>
              <p className={style.articleSubTitles}>THE FOUNDRY, Philadelphia, PA -  
                <a className={style.articleLinks} target="_blank" rel="noopener noreferrer" href="https://www.whipradiotu.com/photos-review-hotbed-at-the-foundry/">
                  https://www.whipradiotu.com/photos-review-hotbed-at-the-foundry/
                </a> 
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

About.displayName = "About";
export {About}
