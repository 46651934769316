import {style as s} from 'typestyle';
import * as Style from '../../styles';

const family = 'Oswald';

export const component = s({
    padding: 20,
    position: 'relative'
});

export const image = s({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: -1,
    boxShadow: '0 5px 15px #808080',
});

export const imageContent = s({
    width: '100%',
    height: '100%',
    opacity: 0.85,
    zIndex: -1,
});

export const container = s({
    padding: 20,
    boxSizing: "border-box",
    zIndex: 3,
});

export const header = s({
    fontSize: 35,
    fontFamily: family,
    textTransform: "uppercase",
    textShadow: "1px 1px 2px rgba(220,220,220)",
    color: Style.colors.black3,
    borderBottom: "4px solid",
    borderColor: Style.colors.palletPurple,
    textAlign: "center",
    marginTop: "-1rem",
})

export const subHeaderContainer = s({
})

export const subHeader = s({
    fontSize: 14,
    fontFamily: family,
    color: Style.colors.black3,
    fontWeight: 200,
    padding: 4,
    borderRadius: 8,
});

export const book = s(Style.text.largeHeader, {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    textDecoration: "none",
  });

export const button = s({
    width: 62.5,
    height: 40,
    fontSize: 16,
    fontWeight: 600,
    backgroundColor: Style.colors.black2,
  });
