import { media, style as s } from "typestyle";
import * as Style from "../../styles";
import Header from "../../assets/siteHeader.jpg";

const family = "Monoton";

export const component = s({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "75vh",
  padding: 50,
});

export const siteHeader = s(
  {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundImage: `linear-gradient(to top, rgba(0, 0, 0, 0.65), rgba(225, 225, 225, 0.1)), url(${Header})`,
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
    width: "100vw",
    height: "100vh",
    },
    media(
      { minWidth: Style.screensize.mdMax },
      {
        marginTop: "40px",
      }
    )
);

export const photoHeader = s({
  background:"white",
  width: "350px",
},
media(
  { minWidth: Style.screensize.xsMax },
  {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "auto",
    background:"white",
    width: "1000px",
    height: "70vh",
  }
))

export const subPhotoHeader = s({
  width: "350px"
},
  media(
    { minWidth: Style.screensize.xsMax },
    {
      padding: "1rem 1rem 1rem 1rem",
      width: "50%",
      height: "70vh",  
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      margin: "auto"
    }
  )
)

export const albumPicture = s({
  width: "350px",
},
media(
  { minWidth: Style.screensize.xsMax },
  {
    padding: "1rem 1rem 1rem 1rem",

    width: "50%",
    height: "70vh",
  }
))

export const button = s({
  backgroundColor: "rgba(111,35,72,255)",
  borderRadius: 4,
  fontSize: Style.text.fontSize.medium,
  letterSpacing: 1,
  color: Style.colors.white2,
  padding: "30px",
  marginBottom: -60,
  $nest: {
      "&:hover": {
        backgroundColor: "rgba(199,68,100,255)",
        transition: '0.25s',
      },
  }
},
media(
  { minWidth: Style.screensize.smMax },
  {
    padding: "30px",
  }
))

export const forLooks = s({
  width: "33.3%"
})

export const tshirtPromoContainer = s({
  height: "100vh",
  display: "flex",
  flexGrow: 1,
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: Style.colors.palletMustard
},
media(
  { minWidth: Style.screensize.mdMax },
  {
    flexDirection: "row"
    }
))

export const tshirtPromoTitle = s({
  fontSize: "100px",
  fontFamily: Style.text.font.Montserrat,
  textTransform: "uppercase",
  margin: "0 -8rem 0 0",
  zIndex: 1,
  color: Style.colors.black2

})

export const tshirtPromoTitleContainer = s({
  marginTop: "1rem",
  fontSize: "100px",
  fontFamily: Style.text.font.Montserrat,
  lineHeight: "105px",
  textTransform: "uppercase",
  zIndex: 1
},
media(
  { maxWidth: Style.screensize.mdMax },
  {
    display: "none"
  }
))

export const tshirtPromoImg = s({
  height: "370px",
  width: "350px"
},
media (
  { minWidth: Style.screensize.mdMax},
  {
    height: "620px",
    width: "600px"
  }
)
)

export const showPromoImg = s({
  height: "370px",
  width: "350px"
},
media (
  { minWidth: Style.screensize.mdMax},
  {
    height: "370px",
    width: "350px"
  }
))

export const tourContainer = s({
  height: "100vh",
  background: "linear-gradient(315deg, #d9d9d9 0%, #f6f2f2 4%)",
  display: "flex",
})

export const img = s({
  display: "none"
},
media(
  { minWidth: Style.screensize.mdMax },
  {
    width: "50vw",
    maxWidth: "600px",
    borderImage: `linear-graident(to right, rgba(225, 225, 225) 0%, rgba(225, 225, 225) 100%)`,
    display: "inline"
  }
))

export const tourDates =s({
  textAlign: "center",
  width: "75%",
  margin: "auto"
})

export const tourTitle = s({
  fontSize: Style.text.fontSize.extraLarge,
  fontFamily: family
},
media(
  { minWidth: Style.screensize.mdMax },
  {
    fontSize: Style.text.fontSize.xxxl, 
    // padding: "10px"
  }
))

export const merchTitle = s({
  fontFamily: family,
  fontSize: Style.text.fontSize.extraLarge,
  borderBottom: `2px solid ${Style.colors.darkTranslucent650}`,
  textAlign: "center"
},
media(
  { minWidth: Style.screensize.smMax },
  {
    fontSize: Style.text.fontSize.xxxl, 
  }
))

export const shop = s({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center"
})

export const card = s({
  maxWidth: '350px',
})

export const articleContainer = s({
  backgroundColor: "linear-gradient(315deg, #ffffff 0%, #ecd7dc 74%)",
  display: "flex",
  flexWrap: "wrap",
  alignItems: "space-between",
  width: "100%"
})

export const articlePhoto = s({
  height: "300px",
  maxWidth: "400px",
  borderRadius: "50%",
  textAlign: "center",
  padding: 20,
},
media(
  { minWidth: Style.screensize.smMax },
  {
    padding: 20,
  }
))

export const articleSubContainer = s({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  margin: 10,
},
media(
  { minWidth: Style.screensize.smMax },
  {
    marginLeft: 80,
  }
))

export const articleTitles = s({
  textTransform: "uppercase",
  fontSize: Style.text.fontSize.medLarge,
  color: Style.colors.black2
})

export const articleSubTitles = s({
  fontSize: Style.text.fontSize.small,
  color: Style.colors.darkTranslucent650,
  flex: 1,
})

export const articleLinks = s({
  textDecoration: "none",
  color: "rgba(111,35,72,255)",
  fontSize: Style.text.fontSize.small,
  paddingLeft: "1rem",
  $nest: {
    "&:hover": {
      color: "rgba(199,68,100,255)",
      transition: '0.25s',
    },
  }
})

export const musicContainer = s({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  alignItems: "center",
},
media(
  { minWidth: Style.screensize.smMax },
  {
    justifyContent: "space-around",
  }
))

export const streamingButtonContainer = s({
  display: "flex",
  flexDirection: "column"
},
media(
  { minWidth: Style.screensize.smMax },
  {
    flexDirection: "row"
  }
))

export const streamingButtons = s({
  textDecoration: "none",
  textAlign: "center",
  fontSize: Style.text.fontSize.medium,
  color: Style.colors.white,
  margin: 40,
  backgroundColor: "rgba(199,68,100,255)",
  borderRadius: 4,
  padding: 10,
  $nest: {
      "&:hover": {
        backgroundColor: "rgba(111,35,72,255)",
        transition: '0.25s',
      },
  }
})

export const spotifyPlaylist = s({
  margin: 10
},
media(
  { minWidth: Style.screensize.smMax },
  {
    margin: 80
  }
))
