import { media, style as s } from "typestyle";
import * as Style from "../../styles";

const family = 'Oswald';

export const component = s(
  {
    backgroundColor: Style.colors.darkTranslucent100,
    padding: 20,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      display: "flex",
      padding: 50,
      justifyContent: "flex-start",
    }
  )
);

export const content = s(
  {
    color: Style.colors.darkTranslucent650,
    fontSize: 14,
    lineHeight: 1.5,
    marginBottom: 20,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      flex: 1,
      lineHeight: 1.7,
      fontSize: 18,
    }
  )
);

export const logo = s(
  {
    width: "70%",
    margin: "auto",
  },
  media(
    { maxWidth: Style.screensize.smMax },
    {
      display: "none",
    }
  )
);

export const header = s(
  {
    fontFamily: family,
    ...Style.text.smallHeader,
    marginBottom: 10,
    color: Style.colors.primary,
    borderBottom: Style.colors.primary,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      ...Style.text.mediumHeader,
    }
  )
);

export const iconContainer = s ({
  display: "flex",
  justifyContent: "center",
  background: Style.colors.white,
  padding: "20px"
  // marginLeft: 35,
})

export const socialmediaIcons = s (
  {
  color: Style.colors.black1,
  fontSize: Style.text.fontSize.xxl,
  margin: "0 10px 5px 10px",
  $nest: {
    "&:hover": {
      color: Style.colors.primary,
      transition: "0.3s"
    }
  }
  },
  media(
    { maxWidth: Style.screensize.mdMax },
    {
      margin: 5,
    }
  ))

export const secondFooter = s({
  display: "flex",
  justifyContent: "center",
  padding: 10,
  height: 40,
  width: "100%",
  color: Style.colors.black1,
  backgroundColor: Style.colors.white,
})
