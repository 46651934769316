import { important, px } from "csx";
import { style } from "typestyle";
import * as Style from "../../styles";

export const component = style({
  height: px(40),
  whiteSpace: "nowrap",
  $nest: {
    "&:focus": {
      outline: "none",
    },
  },
  textDecoration: "none",
  color: Style.colors.white,
});

export const colored = style({
  backgroundColor: Style.colors.black1,
  $nest: {
    "&:hover": {
      backgroundColor: Style.colors.palletPurple,
    },
  },
});

export const rounded = style({
  borderRadius: important(px(5)),
});

export const secondary = style({
  color: Style.colors.palletPurple,
  borderColor: Style.colors.palletPurple,
});
