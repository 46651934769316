import {style as s} from 'typestyle';
import * as Style from '../../styles';

const family = 'Oswald';

export const image = s({
    boxShadow: '0 5px 15px #808080',
    height: 400
});

export const imageContent = s({
    width: '100%',
    height: '100%',
    opacity: 0.95,
});

export const container = s({
    padding: 10,
    boxSizing: "border-box",
});

export const header = s({
    fontSize: Style.text.fontSize.large,
    fontFamily: family,
    textTransform: "uppercase",
    textShadow: "1px 1px 2px rgba(220,220,220)",
    color: Style.colors.black1,
    borderBottom: "2px solid",
    borderColor: Style.colors.darkTranslucent650,
    textAlign: "center",
})

export const subHeaderContainer = s({
    maxWidth: '300px',
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
})

export const subHeader = s({
    fontSize: 14,
    fontFamily: family,
    color: Style.colors.darkTranslucent650,
    fontWeight: 200,
    padding: 2,
});

export const book = s(Style.text.largeHeader, {
    textAlign: "center",
    textDecoration: "none",
  });

export const button = s({
    width: 70,
    height: 40,
    fontSize: 16,
    fontWeight: 600,
    backgroundColor: Style.colors.black2,
    padding: 10
  });

  export const modal = s(
    {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "white",
    }
  );
  
  export const close = s({
    position: "absolute",
    top: 5,
    right: 5,
    $nest: {
      "& svg": {
        fontSize: 25,
        fontWeight: 700,
      },
    },
  });
  
  