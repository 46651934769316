import { style as s, media } from "typestyle";
import * as Style from "../../styles";

const family = "Monoton";

export const background = s({
  backgroundColor: "#485461",
  backgroundImage: "linear-gradient(to top, #8297ad 0%, #28313b 100%)"
})

export const component = s(
  {
    marginTop: 85,
    marginLeft: 100,
    marginRight: 100,
    padding: 20,
  },
  media(
    { maxWidth: Style.screensize.mdMin },
    {
      marginLeft: 5,
      marginRight: 5,
    }
  )
);

export const title = s(Style.text.extraLargeHeader, {
  paddingLeft: 20,
  paddingBottom: 10,
  color: Style.colors.white,
  marginBottom: 25,
  borderBottom: `1px solid ${Style.colors.white2}`,
  fontWeight: 300,
});

export const subTitleSection = s(
  {
    display: "flex",
    marginTop: 30,
    marginBottom: 30,
    alignItems: "center",
  },
  media(
    { maxWidth: Style.screensize.smMin },
    {
      flexDirection: "column",
    }
  )
);

export const subTitle = s(
  {
    fontFamily: family,
    textAlign: "center",
    fontSize: Style.text.fontSize.xxl,
    fontWeight: 200,
    color: Style.colors.white,
    flex: 1,
  },
  media(
    { maxWidth: Style.screensize.mdMax },
    {
      fontSize: Style.text.fontSize.extraLarge,
      padding: `15px 10px`,
    }
  ),
  media(
    { maxWidth: Style.screensize.smMin },
    {
      fontSize: Style.text.fontSize.extraLarge,
      paddingBottom: 10,
      borderRight: "none",
      borderBottom: `1px solid ${Style.colors.white2}`,
    }
  )
);

export const bandPhoto = s ({
  maxWidth: 600, 
  textAlign: "center", 
  margin: "auto", 
  boxShadow: '0 5px 15px #596470',
})

export const aboutWrapper = s({
  display: "flex",
  flexDirection: "column",
});

export const aboutUs = s({
  marginTop: 50,
  marginBottom: 50,
  color: Style.colors.white
})

export const articleSubContainer = s({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  margin: 10,
},
media(
  { minWidth: Style.screensize.smMax },
  {
    marginLeft: 80,
  }
))

export const articleTitles = s({
  textTransform: "uppercase",
  fontSize: Style.text.fontSize.medLarge,
  color: Style.colors.white2
})

export const articleSubTitles = s({
  fontSize: Style.text.fontSize.small,
  color: Style.colors.white2,
  flex: 1,
})

export const articleLinks = s({
  color: Style.colors.white2,
  fontSize: Style.text.fontSize.small,
  paddingLeft: "1rem",
  $nest: {
    "&:hover": {
      color: Style.colors.white,
      transition: '0.25s',
    },
  }
})