import * as React from 'react';
import TshirtArt from '../../assets/NewT_Artwork 2.jpg';
import * as style from './LandingPage.style';
import { useSpring, animated } from 'react-spring';

interface ComponentProps {
  onEnter: () => void;
}

const LandingPage: React.FC<ComponentProps> = ({onEnter}) => {

    const springs = useSpring({
      delay: 500,
      to: { opacity: 1 },
      from: { opacity: 0},
      config: { duration: 1000 },
    });
        
    return(
      <div className={style.container}>
        <animated.div style={springs}>
          <img src={TshirtArt} className={style.faceImage} alt="Tshirt design"/>
        </animated.div>
      </div>
    );
};

LandingPage.displayName = "LandingPage";
export {LandingPage};