import { style as s } from "typestyle";
import * as Style from "../../styles";
import BackgroundImage from "../../assets/NewT_Artwork 2.jpg";

export const backgroundImg = s({
  backgroundImage: BackgroundImage,
})

export const shop = s({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
})

export const storeTitle = s({
  textAlign: "center",
  fontFamily: Style.text.font.third,
  textTransform: "uppercase"
})

export const card = s({
  maxWidth: '350px',
})

export const productContent = s({
  width: "100%",
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  padding: "30px 0",
});

export const product = s({
  flex: "0 0 300px",
  marginLeft: 20,
  marginRight: 20,
  marginBottom: 40,
  boxShadow: `0 2px 10px rgba(0,0,0,0.1)`,
});