import * as React from "react";
import { classes } from "typestyle";
import * as style from "./CartItem.style";
import { IconButton } from "../../IconButton";
import * as Icons from "../../Icons";
import { LineItem, Client, Cart } from "shopify-buy";
import { ActionTypes } from "../../../store/actions";
import { useDispatch } from "react-redux";

export interface ComponentProps {
  /**
   * Optional className to apply to the component
   */
  className?: string;
  /**
   * line item
   */
  lineItem: LineItem;
  /**
   * shopify client
   */
  client: Client;
  /**w
   * Shopify cart
   */
  cart: Cart;
}
/**
 * CartItem component
 * Container component for cart items
 */
const CartItem: React.FC<ComponentProps & { children?: React.ReactNode }> = ({
  className,
  lineItem,
  client,
  cart,
}) => {
  const dispatch = useDispatch();
  return (
    <>
      <div className={classes(className, style.component)}>
        <div className={style.product}>
          <div className={style.close}>
            <IconButton
              size="small"
              onClick={() =>
                removeFromCart(lineItem.id.toString(), client, cart, dispatch)
              }
            >
              <Icons.Close />
            </IconButton>
          </div>
          {!!(lineItem as any).variant.image.src && (
            <div className={style.image}>
              <img
                src={(lineItem as any).variant.image.src}
                alt={lineItem.title}
              />
            </div>
          )}
          <div className={style.title}>{lineItem.title}</div>
        </div>
        {/* <div className={style.section}>}</div> */}
        <div className={style.section}>${(lineItem as any).variant.price}</div>
        <div className={style.quantityWrapper}>
          <IconButton
            size="small"
            onClick={() =>
              updateQuantity(
                lineItem.id,
                lineItem.quantity - 1,
                client,
                cart,
                dispatch
              )
            }
            disabled={lineItem.quantity < 2}
          >
            <Icons.IndeterminateCheckBoxIcon />
          </IconButton>
          <div className={style.quantity}>{lineItem.quantity}</div>
          <IconButton
            size="small"
            onClick={() =>
              updateQuantity(
                lineItem.id,
                lineItem.quantity + 1,
                client,
                cart,
                dispatch
              )
            }
          >
            <Icons.AddBox />
          </IconButton>
        </div>
        <div className={style.total}>
          ${((lineItem as any).variant.price * lineItem.quantity).toFixed(2)}
        </div>
      </div>
    </>
  );
};

const updateQuantity = (
  id: string | number,
  quantity: number,
  client: Client,
  cart: Cart,
  dispatch: any
) => {
  if (cart && client) {
    client.checkout
      .updateLineItems(cart.id, [{ id, quantity }])
      .then((newCart) => {
        dispatch({
          type: ActionTypes.CART_UPDATED,
          payload: { cart: newCart },
        });
      });
  }
};

const removeFromCart = (
  id: string,
  client: Client,
  cart: Cart,
  dispatch: any
) => {
  if (cart && client) {
    client.checkout.removeLineItems(cart.id, [id]).then((newCart) => {
      dispatch({
        type: ActionTypes.CART_UPDATED,
        payload: { cart: newCart },
      });
    });
  }
};

CartItem.displayName = "CartItem";
export { CartItem };
