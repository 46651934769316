import * as React from "react";
import * as style from "./Product.style";
import { classes } from "typestyle";
import { Cart, Client, Product as ShopifyProduct } from "shopify-buy";
import { connect  } from "react-redux";
import { Dropdown } from "../../Dropdown";

export interface StateProps {
  client: Client;
  cart: Cart;
}

export interface RootProps {
  className?: string;
  product: ShopifyProduct;
}

export interface ComponentProps extends StateProps, RootProps {}

/**
 * Shopify Product component
 */
const ProductInternal: React.FC<ComponentProps> = ({
  className,
  product,
  client,
  cart,
}) => {
  // const dispatch = useDispatch();
  const image = product.images.length ? product.images[0] : null;
  // const [quantity, setQuantity] = React.useState<number>(1);
  const currentVariant = product.variants[0];
  const productSizes = () => {
    return ["Small", "Medium", "Large", "XL", "XXL"];
  }
  const [showDropDown, setShowDropDown] = React.useState<boolean>(false);
  // const sizes = product.options[0].values;
  const [size, setSize] = React.useState<string>(""); 

  React.useEffect(() => {
    setShowDropDown(showDropDown);
  }, [showDropDown])

  const onSelectSize = (size: string) => {
    setSize(size);
  }

  const toggleDropDown = () => {
    setShowDropDown(!showDropDown);
  };

  const dismissHandler = (event: React.FocusEvent<HTMLButtonElement>): void => {
    if (event.currentTarget === event.target) {
      setShowDropDown(false);
    }
  };

  return (
    <div className={classes(className, style.component)}>
      {!!image && (
        <img
          src={image.src}
          alt={`${product.title} product shot`}
          className={style.image}
        />
      )}
      <div className={style.content}>
        <div className={style.title}>{product.title}</div>
        <div className={style.titleBorder}></div>
        <div className={style.titleContainer}>
          <div className={style.price}>${currentVariant.price}</div>
          <div style={{width: "2-00px"}}>
            <button
              className={showDropDown ? style.active : style.button}
              onClick={(): void => toggleDropDown()}
              onBlur={(e: React.FocusEvent<HTMLButtonElement>): void =>
                dismissHandler(e)
              }
            >
              <div>{size !== "" ? size : "Select size"} </div>
              {showDropDown && (
                <Dropdown 
                  items={productSizes()}
                  showDropDown={false}
                  toggleDropDown={(): void => toggleDropDown()}
                  itemSelection={onSelectSize}
                />
              )}
            </button>
          </div>
        </div>
        <div className={style.addToCartContainer}>
          <div
            className={style.addToCartButton}
            // onClick={() =>
              // addVariantToCart(
              //   currentVariant.id,
              //   // quantity,
              //   client,
              //   cart,
              //   dispatch
              // )
            // }
          >
            Add to Cart
          </div>
        </div>
      </div>
    </div>
  );
};

// const addVariantToCart = (
//   variantId: string | number,
//   quantity: number,
//   client: Client,
//   cart: Cart,
//   dispatch: any
// ) => {
//   const lineItemsToAdd = [{ variantId, quantity }];
//   if (cart && client) {
//     client.checkout.addLineItems(cart.id, lineItemsToAdd).then((newCart) => {
//       dispatch({ type: ActionTypes.CART_UPDATED, payload: { cart: newCart } });
//       // toast.success(`Item${quantity > 1 ? "s" : ""} added to cart`);
//     });
//   }
// };

ProductInternal.displayName = "Product";

export const Product = connect(
  (state: any) => ({
    client: state.shopify.client,
    cart: state.shopify.cart,
  }),
  {}
)(ProductInternal);
