import { media, style as s } from "typestyle";
import * as Style from "../../../styles";

export const modal = s(
  {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "white",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      top: 100,
      left: 100,
      right: 100,
      bottom: 100,
    }
  )
);

export const close = s({
  position: "absolute",
  top: 5,
  right: 5,
  $nest: {
    "& svg": {
      fontSize: 25,
      fontWeight: 700,
    },
  },
});

export const component = s({
  padding: `40px 20px 20px`,
  fontFamily: Style.text.font.second,
});

export const title = s({
  textAlign: "center",
  ...Style.text.largeHeader,
  borderBottom: `1px solid ${Style.colors.darkTranslucent300}`,
  marginBottom: 30,
  paddingBottom: 15,
});

export const content = s(
  {
    fontSize: Style.text.fontSize.medium,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      display: "flex",
    }
  )
);

export const items = s(
  {
    flex: 3,
    marginBottom: 30,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      borderRight: `1px solid ${Style.colors.darkTranslucent100}`,
      paddingRight: 20,
    }
  )
);

export const labels = s(
  {
    display: "flex",
    ...Style.text.smallHeader,
    fontSize: Style.text.fontSize.small,
    borderBottom: `1px solid ${Style.colors.darkTranslucent100}`,
    marginBottom: 10,
    paddingBottom: 10,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      ...Style.text.smallHeader,
    }
  )
);

export const productLabel = s({
  flex: 3,
});

export const label = s({
  flex: 1,
  $nest: {
    "&:last-child": {
      textAlign: "right",
    },
  },
});

export const lineItem = s({
  borderBottom: `1px solid ${Style.colors.darkTranslucent100}`,
  $nest: {
    "&:last-child": {
      borderBottom: "none",
    },
  },
});

export const totals = s(
  {
    flex: 2,
    fontWeight: 600,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      paddingLeft: 30,
    }
  )
);

export const totalsHeader = s({
  ...Style.text.smallHeader,
  borderBottom: `1px solid ${Style.colors.darkTranslucent100}`,
  paddingBottom: 10,
  marginBottom: 5,
});

export const totalsLine = s({
  display: "flex",
  padding: `5px 0px`,
  justifyContent: "space-between",
  borderBottom: `1px solid ${Style.colors.darkTranslucent100}`,
  textTransform: "uppercase",
  fontSize: Style.text.fontSize.small,
  $nest: {
    "&:last-child": {
      borderBottom: "none",
      marginBottom: 40,
    },
  },
});

export const shippingAmount = s({
  marginBottom: 5,
  textAlign: "right",
});

export const tax = s({
  display: "flex",
  alignItems: "center",
  fontSize: Style.text.fontSize.small,
  fontWeight: 400,
  color: Style.text.color.subtle,
  maxWidth: 200,
  textAlign: "right",
});

export const disclaimer = s({
  fontSize: Style.text.fontSize.extraSmall,
  fontWeight: 400,
  color: Style.text.color.subtle,
  maxWidth: 200,
  textAlign: "right",
  $nest: {
    "& a": {
      color: Style.text.color.subtle,
    },
  },
});

export const checkoutButtonWrapper = s(
  {
    display: "flex",
    justifyContent: "center",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      paddingTop: 30,
    }
  )
);

export const checkoutButton = s(
  {
    border: `2px solid ${Style.colors.palletPurple}`,
    borderRadius: 4,
    color: Style.colors.palletPurple,
    textDecoration: "none",
    textTransform: "uppercase",
    fontSize: Style.text.fontSize.medium,
    padding: "10px 15px",
    backgroundColor: "transparent",
    margin: "auto",
    cursor: "pointer",
    $nest: {
      "&:hover": {
        backgroundColor: Style.colors.primary,
        color: Style.colors.white,
        transition: "0.3s",
      },
    },
  },
  media(
    { minWidth: Style.screensize.mdMin },
    { fontSize: Style.text.fontSize.large }
  )
);

export const empty = s({
  ...Style.text.extraLargeHeader,
  color: Style.text.color.subtle,
  fontFamily: Style.text.font.second,
  fontWeight: 200,
  textAlign: "center",
  padding: `30px 75px`,
  marginTop: 100,
});
