import * as React from "react";
import { classes } from "typestyle";
import * as style from "./Page.style";

export interface ComponentProps {
  /**
   * Optional className to apply to the component
   */
  className?: string;
}
/**
 * Page component
 * Container component for pages
 */
const Page: React.FC<ComponentProps & { children?: React.ReactNode }> = ({
  className,
  children,
}) => {
  return (
    <>
      <div className={classes(className, style.component)}>
        <div className={style.content}>{children}</div>

      </div>
    </>
  );
};

Page.displayName = "Page";
export { Page };
