// prettier-ignore
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import { createBrowserHistory } from "history";
import * as React from "react";
import { Route, Router } from "react-router-dom";
import { Header } from "../components";
import { Home, About, LandingPage } from "../pages";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Routes() {
  // attach auth token for authenticated firebase users to all outgoing requests
  return (
    <div>
    <Route strict exact={true} path="/home" component={Home} />
    <Route strict exact={true} path="/about" component={About} />
    {/* <Route strict exact={true} path="/store" component={Shop} /> */}
    <Route strict exact={true} path="/" component={LandingPage} />
     <div style={{ display: "none" }}>
        Metadata with keywords about site goes here for SEO
      </div>
    </div>
  );
}

const history = createBrowserHistory();

export const App = () =>{
  const classes = useStyles();

  return (
    <Router history={history}>
      <div className={classes.root}>
        <Header />
        <Routes />
      </div>
      {/* <ShopifyStartup />
      <Shopify.Cart /> */}
      <ToastContainer
        autoClose={2000}
        position="top-right"
        newestOnTop={false}
        hideProgressBar={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
    </Router>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    height: "100%",
    zIndex: 1,
    overflow: "hidden",
  },
}));

