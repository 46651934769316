import React from "react";
import * as styles from "./Home.style";
import { useSpring, animated } from 'react-spring';
import GoogleFontLoader from "react-google-font-loader"
import Story from "../../assets/Story.jpg";
import forLooks from "../../assets/img_0317.jpeg";
import forLooks2 from "../../assets/img_0320.jpeg";
import forLooks3 from "../../assets/img_0318.jpeg";
import ValentiPromo from "../../assets/valenti-tee-promo.jpeg";
import Starboard from "../../assets/hotbed-starboard-7:17:2021.png";
import { palletPink } from "../../styles/colors";

const family = "Monoton";

const Home: React.FC = () => {

  const spring = useSpring({
    delay: 300,
    to: { opacity: 1, transform: 'translateY(0px)' },
    from: { opacity: 0, transform: 'translateY(50px)' },
    config: { duration: 1000 },
  });

  const springs = useSpring({
    delay: 300,
    to: { opacity: 1 },
    from: { opacity: 0},
    config: { duration: 1000 },
  });

  return (
    <>
      <div>
        <GoogleFontLoader 
          fonts={[
            { font: family, weights: [400, 500, 600] },
            { font: 'Roboto', weights: [400, 900] },
            { font: 'Roboto Mono', weights: [400, 700, 900] }
          ]} 
          subsets={['cyrillic-ext', 'greek']}
        />
      </div>
      <animated.div className={styles.tshirtPromoContainer}>
        <div className={styles.tshirtPromoTitleContainer}>
          <p style={spring} className={styles.tshirtPromoTitle}>T-shirts<br />back in <div style={{lineHeight: "120px", color: palletPink, fontSize: "130px", textTransform: "uppercase"}}>Stock!</div></p>
        </div>
        <img style={springs} className={styles.tshirtPromoImg} src={ValentiPromo} alt="tshirt promo" />
      </animated.div>
      <div style={springs} className={styles.tourContainer}>
        <div style={spring} className={styles.tourDates}>
          <h2 className={styles.tourTitle}>UPCOMING SHOWS</h2>
          <h3> Dewey Beach, DE - The Starboard - 10pm-1am w/ The Haunting</h3>
          <p>T-shirts will be available!</p>
          <img src={Starboard} className={styles.showPromoImg} alt="Promo poster for Starboard show on 7/17/2023"/>
          <h3>We are currently recording . . .</h3>
          <p>See you soon. :)</p>
        </div>
        <img src={Story} className={styles.img} alt="collage of band photos"/>
      </div>
      <animated.div>
        <img src={forLooks} className={styles.forLooks} alt="hotbed drawing"/>
        <img src={forLooks2} className={styles.forLooks} alt="hotbed drawing"/>
        <img src={forLooks3} className={styles.forLooks} alt="hotbed drawing"/>

      </animated.div>
      <div className={styles.musicContainer}>
        <div className={styles.streamingButtonContainer}>
          <a className={styles.streamingButtons} target="_blank" rel="noopener noreferrer" href="https://open.spotify.com/artist/4B7zPlvJ8AofK8IZ7TuN1y?si=OUcJczwXRoiXeSWjfRZN5w&dl_branch=1">Go To Spotify</a>
          <a className={styles.streamingButtons} target="_blank" rel="noopener noreferrer" href="https://music.apple.com/us/artist/hotbed/1277755274">Go To Apple Music</a>
          <a className={styles.streamingButtons} target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCIad9Gp1zIoZ6vU1y8UtsTQ/featured">Go To Youtube</a>

        </div>
        <iframe title="hotbed-spotify" className={styles.spotifyPlaylist} src="https://open.spotify.com/embed/artist/4B7zPlvJ8AofK8IZ7TuN1y?theme=0" padding-top="4rem" height="380" frameBorder="0" allow="encrypted-media"></iframe>
      </div>
    </>
  );
};

Home.displayName = "Home";
export { Home };
