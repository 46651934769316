import { style as s } from "typestyle";
import * as Style from "../../../styles";
import ValentiBacksideT from "../../../assets/valenti-back.png";

export const component = s({
  borderRadius: 2,
  font: Style.text.font.main,
  margin: "auto",
});

export const image = s({
  background: Style.colors.white,
  width: 307,
  height: 400,
  $nest: {
    "&:hover": {
      cursor: "pointer",
      backgroundImage: ValentiBacksideT
    },
  }
});

export const content = s({
  backgroundColor: Style.colors.white,
  width: "100%",
  padding: `10px 0px`,
});

export const titleContainer = s({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  padding: "0 1rem"
})

export const title = s({
  textAlign: "center",
  fontFamily: Style.text.font.second,
  fontSize: Style.text.fontSize.xl,
  fontWeight: 500,
  paddingLeft: "1rem",
  marginBottom: 10,
  color: Style.colors.black3,
});

export const titleBorder = s({
  marginTop: "-2rem",
  padding: "0.5rem",
  borderTop: `15px solid ${Style.colors.palletPink}`
})

export const dropdown = s({
  marginRight: "1rem",
  position: "absolute"
})

export const details = s({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "flex-end",
  padding: "1rem 1rem 0 1rem",
  color: "$464646",
});

export const productDescContainer = s({
  display: "flex",
  flexDirection: "row"
});

export const price = s({
  fontSize: Style.text.fontSize.medium,
  fontFamily: Style.text.font.second,
  fontWeight: 300,
  marginTop: "0.25rem"
});

export const quantitySection = s({
  display: "flex",
  justifyContent: "flex-end"
});

export const quantityInput = s({
  width: "60px",
});

export const quantityButtons = s({
  width: 30
});

export const button = s({
  cursor: "pointer",
  width: 115,
  padding: "5px 0 5px 0",
  fontFamily: Style.text.font.fifth,
  fontSize: Style.text.fontSize.smallMed,
  border: `.5px solid ${Style.colors.black2}`,
  backgroundColor: Style.colors.black2,
  color: Style.colors.white2,
  borderRadius: 2,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  transition: ".15s ease-in",
  $nest: {
    "&:hover": {
      backgroundColor: Style.colors.white,
      color: Style.colors.black2,
    },
  },
});

export const addToCartButton = s({
  cursor: "pointer",
  padding: "5px 20px",
  margin: "1rem 0",
  fontFamily: Style.text.font.fifth,
  fontSize: Style.text.fontSize.medLarge,
  textTransform: "uppercase",
  border: `.5px solid ${Style.colors.black2}`,
  backgroundColor: Style.colors.black2,
  color: Style.colors.white2,
  borderRadius: 2,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  transition: ".15s ease-in",
  $nest: {
    "&:hover": {
      backgroundColor: Style.colors.white,
      color: Style.colors.black2,
    },
  },
});

export const active = s({
  color: Style.colors.black2,
  backgroundColor: Style.colors.white,
})

export const addToCartContainer = s({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "auto"
})
